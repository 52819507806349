import {
  IAutoSweetInventory,
  ICarsForSalesInventory,
  IDealerSpikeInventory,
  IDealersyncInventory,
  IEDealerInventory,
  IHomenetInventory,
  InventoryStatusTypes,
  ISertiInventory,
  ISM360Inventory,
  IVAutoInventory,
  IVincueInventory,
} from '@/types/response'
import { permissionType } from '@matador/automations-lib/src/types/permissions'
import { RQBJsonLogic } from 'react-querybuilder'

export type IconNames = 'loading'

export type IPageMeta = { page: number; pageSize: number }

export type TFunc = () => void

export type TPCallback = (..._params: any[]) => void

export type BasePlacement = 'top' | 'right' | 'bottom' | 'left'

export interface IParams {
  [x: string]: string | number | undefined | null
}

export interface IMenu {
  label: string
  path: string
  children?: IMenu[]
  access?: permissionType[]
}

export type VariantPlacement =
  | 'top-start'
  | 'top-end'
  | 'top-center'
  | 'bottom-start'
  | 'bottom-end'
  | 'bottom-center'
  | 'right-start'
  | 'right-end'
  | 'right-center'
  | 'left-start'
  | 'left-end'
  | 'left-center'

export type ArgumentType = string | number | boolean | any

export interface ITab<T> {
  key: T
  label: React.ReactNode
}

export enum TOrganizationTab {
  AUTOMATIONS = 'automations',
  TASKS = 'tasks',
  APPOINTMENTS = 'appointments',
  LEADS = 'leads',
  AI_BOT_CUSTOMERS = 'ai_bot_customers',
  ADD_AUTOMATION = 'add_automation',
  SETTINGS = 'settings',
  REPAIR_ORDERS = 'repair_orders',
  SERVICE_APPOINTMENTS = 'service_appointments',
  USERS = 'users',
}

export type Placement = BasePlacement | VariantPlacement

export type Rect = Pick<
  DOMRect,
  'left' | 'top' | 'bottom' | 'right' | 'height' | 'width'
>

export type TIconName = any

export interface IOption<T = any> {
  value: T
  label: string
  disabled?: boolean
}

export interface IStatItem {
  pending: number
  completed: number
  failed: number
  successRate: string
}

export interface IStats {
  totalCompleted: number
  appointment: IStatItem
  automation: IStatItem
}

export interface IOrganization {
  _id: string
  name: string
}

export interface IAutomation {
  _id: string
  name: string
  type: string
}

export interface ILocation {
  _id: string
  name: string
}

interface IPayload {
  tag: TagStatus
  message: string
  content?: any
  lambdaLogs?: string
  parsedRowsCount?: number
  fetchedRowsCount?: number
  screenshotLocation?: string
}

export interface IEvent {
  _id: string
  type: string
  payload: IPayload
  createdAt: string
}

export interface ITaskParsedPayload {
  [key: string]: string
}

export interface ITask {
  _id: string
  step: number
  crm: string
  createdAt: Date
  events: IEvent[]
  location: ILocation
  crmPayload: boolean
  status: TTaskStatus
  parsedPayload?: ITaskParsedPayload[]
  automation: IAutomation
  organization: IOrganization
}

export interface IMeta {
  step: string
  link: string
  task: string
  type: string
  automation: string
  organization: string
  automationName: string
  organizationId: string
}

export interface INotification {
  _id: string
  meta: IMeta
  user: string
  title: string
  message: string
  global: boolean
  createdAt: Date
  acknowledged: boolean
}

export type TFilters = {
  crm: string
  endDate: string
  startDate: string
  locationId: string
  status: TTaskStatus
  automationRule: string
  organizationId: string
  organizationIds: string[]
  externalId: string
  workflowIds: string[]
  automationType: TAutomationType
  dealers: string[]
}

export type TAutomationType =
  | 'automation'
  | 'appointment'
  | 'lead'
  | 'aibot'
  | 'workflow'
  | 'resurrection'
  | 'weeklyreport'

export type TagStatus = 'SUCCESS' | 'ERROR' | 'WARNING' | 'DEFAULT'

export enum TTaskStatus {
  FAILED = 'failed',
  PENDING = 'pending',
  COMPLETED = 'completed',
  ACKNOWLEDGED = 'acknowledged',
}

export enum TA2pStatus {
  FAILED = 'failed',
  PAUSED = 'paused',
  COMPLETED = 'completed',
}

export enum TDateFields {
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
}

export enum TAppointmentStatus {
  CONFIRMED = 'confirmed',
  RESCHEDULED = 'rescheduled',
  CANCELED = 'canceled',
  FAILED = 'failed',
}

export interface ILeadsFilter {
  crm: string
  date: string
  name: string
  phoneNumber: string
  organization: string
}

export interface IRepairOrdersFilter {
  endDate: string
  startDate: string
  externalId: string
  workflowIds: string[]
  location: string
}

export interface IAIBotCustomerFilter {
  endDate: string
  startDate: string
  phoneNumber: string
  organization: string
}

export interface IAppointmentFilter {
  name: string
  endDate: string
  startDate: string
  sortBy: TDateFields
  phoneNumber: string
  organization?: string
  status: TAppointmentStatus
}

export interface IDataContext<T, TFilter = any> {
  data: T
  isLoading: boolean
  totalItems: number
  pageMeta: IPageMeta
  onResetFilters: TFunc
  filters: Partial<TFilter>
  onUpdateFilters: (_filters: Partial<TFilter>) => void
  onPageMetaChange: (_page: number, _pageSize: number) => void
}

export interface ICredential {
  _id: string
  crm: string
  username: string
  location: string
  organization: string
  meta: {
    otp_code: string
    companyid: string
    subscriptionId: string
    statuses?: string[]
  }
  __v: number
  createdAt: string
  lockState?: {
    lockedAt: Date
    lockedBy: string
    reason: string
  }
}

export interface IIpWhitelist {
  ips: string[]
  enabled: boolean
}

export interface ICRMLocation {
  _id: string
  googleBusinessMessages: {
    googleBrandId: string
    googleAgentId: string
    googleLocationId: string
    enable_messages: boolean
    profileName: string
    businessName: string
    authToken: any
    isTokenAlreadyUsedOnes: boolean
  }
  reviewInviteMsg: {
    en: string
    fr: string
    sp: string
  }
  reviewInviteLocatonMsg: {
    en: string
    fr: string
    sp: string
  }
  reviewInviteRatingPage: {
    en: string
    fr: string
    sp: string
  }
  reviewInvitePositivePage: {
    en: string
    fr: string
    sp: string
  }
  reviewInviteNegativePage: {
    en: string
    fr: string
    sp: string
  }
  calendarMsg: {
    en: string
    sp: string
    fr: string
  }
  calendarMsgPic: {
    en: string
    fr: string
    sp: string
  }
  crmConfigs: {
    [crm: string]: {
      scope: string
      token: string
      region: string
      api_key: string
      dealerId: string
      clientId: string
      accountId: string
      client_id: string
      account_id: string
      access_token: string
      redirect_uri: string
      dealerNumber: string
      serialNumber: string
      refresh_token: string
      client_secret: string
      subscriptionId: string
      isSourceMatador: boolean
    }
  }
  additionalConfigs: {
    crmSynchroFlow: number
  }
  integrationInfo: {
    nylas: {
      account_id: string
      access_token: string
      csrf_token: string
      provider: string
      email_address: string
      token_type: string
    }
  }
  friendlyName: string
  enabled: boolean
  disableTimestamp: any
  email: string
  authTokenAccount: string
  appointmentEmail: string
  gmailSessionId: string
  msg_en: string
  msg_fr: string
  msg_sp: string
  pic_en: string
  pic_fr: string
  pic_sp: string
  crmType: string
  GATrackingNumber: string
  name: string
  address: string
  _organization_id: string
  custom_urls: any[]
  createdAt: string
  updatedAt: string
  __v: number
  url_fb: any
  url_google: {
    url: string
    weight: number
  }
  url_yelp: any
}

export interface IMfaConfig {
  enabled: boolean
}

export enum TTag {
  INPUT = 'input',
  SELECT = 'select',
  TEXTAREA = 'textarea',
  SELECT_V2 = 'select_v2',
  MULTIPLE_SELECT = 'multiselect',
  ELEAD_OPTIONS = 'elead-options',
  ACTIVIX_FILTER = 'activixFilter',
  WORKFLOW_SELECT = 'workflow-select',
  PBS_MULTISELECT = 'pbs_multiselect',
  DEALERSOCKET_UUID = 'dealersocket-uuid',
  INPUT_NOT_REQUIRED = 'inputNotRequired',
  START_END_SOLD_ELEAD = 'startEndSoldElead',
}

export interface ICustomField {
  tag?: TTag
  type: string
  name: string
  viewName: string
  options?: any[]
}

export interface IRule {
  _id: string
  name: string
  viewName: string
  crm: string
  steps: {
    type: string
    value: string | number
    action: {
      type: string
      value?: string
    } | null
    tolerance?: boolean
  }[]
  flags: {
    useFutureOffset: any
  }
  htmltable: boolean
  customFields: ICustomField[]
  type: TAutomationType
  dynamicFields: string[]
  createdAt: string
  updatedAt: string
  __v: number
}

export interface IRestrictions {
  restrictTextingCustomersInPendingStatus: boolean
  restrictSupportAgentsAccessToUnassignedFolder: boolean
  restrictSupportAgentsAccessToArchivedFolder: boolean
  restrictSupportAgentsAccessToMainInboxFolder: boolean
  restrictSendingBroadcastsOverTheWeekend: boolean
  calendarInvitationsToUsers: boolean
  restrictSupportAgentsAccessToAllLocations: boolean
  restrictManagersAccessToAllLocations: boolean
  restrictDisplayingPushToCRMButtonToSupportAgents: boolean
  restrictAppointmentsOutsideWorkingHours: boolean
  restrictDisableAIBotManagers: boolean
  restrictDisableAIBotSupportAgents: boolean
  allowParseLeadsWithoutPhone: boolean
  restrictUserVoiceCallsFromUsingOrganizationNumbers: boolean
  restrictEditSuperHumanForManagers: boolean
  restrictEditSuperHumanForSupportAgents: boolean
}

export interface ISuperhumanConfig {
  enabled: boolean
  working_hours: {
    in_range: {
      delay_minutes: number
      enabled: boolean
    }
    off_range: {
      delay_minutes: number
      enabled: boolean
    }
  }
  options: {
    agent_name: string
    inventory_enabled: boolean
    company_type: string
    agent_role: string
  }
  tagging_configuration: {
    tag_users_for_every_message: {
      enabled: boolean
      users_to_tag: string[]
    }
    for_assigned_conversations: string
    tag_within_hours: boolean
    tag_outside_hours: boolean
  }
  instructions: {
    general: string
    human: string
    promotions: string
  }
  customized_intents: any[]
}

export interface ITwilioMessaging {
  twilioNumbers: string[]
  messagingServiceSid: string
}

export interface IWorkingHours {
  Sunday: IWorkingHoursDay
  Monday: IWorkingHoursDay
  Tuesday: IWorkingHoursDay
  Wednesday: IWorkingHoursDay
  Thursday: IWorkingHoursDay
  Friday: IWorkingHoursDay
  Saturday: IWorkingHoursDay
}

interface IWorkingHoursDay {
  isOpen: boolean
  from: string
  to: string
}

export interface ISingOrganizationType {
  _id: string
  __v: number
  name: string
  tags: string[]
  rules: IRule[]
  enabled: boolean
  timezone: string
  updatedAt: string
  createdAt: string
  authToken: string
  languages: string[]
  twilio_number: string
  mfaConfig: IMfaConfig
  enable_payments: boolean
  stripeConnected: boolean
  defaultLanguage: string
  authTokenAccount: string
  appointmentEmail: string
  enable_broadcast: boolean
  enable_auto_reply: boolean
  locations: ICRMLocation[]
  ipWhitelist: IIpWhitelist
  credentials: ICredential[]
  restrictions: IRestrictions
  workingHours: IWorkingHours
  callForwardingNumber: string
  lastMessageHistoryId: string
  stripeAccountId: Maybe<string>
  disableTimestamp: Maybe<boolean>
  stripeExternalAccounts: string[]
  twilioMessaging: ITwilioMessaging
  stripeConnectedDate: Maybe<string>
  superhumanConfig: ISuperhumanConfig
  enable_email_notifications: boolean
  stripeAccountDefaultCurrency: Maybe<string>
}

interface ITimeOffest {
  unit: string
  value: number
  _id: string
}

interface ITime {
  hour: number | string
  minute: number | string
  isoTimezone?: string
}

export interface IFrequency {
  readableText?: string
  increment: string
  days: number[]
  time: ITime
  weekdays: string[]
}

interface IAutomationEvent {
  type: string
  payload: {
    body: any // This can be of any type depending on the payload
  }
  _id: string
  createdAt: string
}

export interface IMedia {
  _id: string
  url: string
  createdBy: string
  createdAt: string
  deletedAt: string
}

export interface IAutomationData {
  _id: string
  crm: string
  user: string
  name: string
  rule: string
  active: boolean
  location: {
    _id: string
    name: string
  }
  organization: string
  meta: {
    end_2: string
    start_2: string
    endUnit_2: string
    startUnit_2: string
    deal_status: string[]
  }
  __v: number
  type: string
  rpaSteps: any[]
  message: string
  public: boolean
  timezone: string
  createdAt: string
  updatedAt: string
  failureCount: number
  initialTest: boolean
  frequencies: IFrequency[]
  endOffset: ITimeOffest
  media: IMedia | null
  startOffset: ITimeOffest
  events: IAutomationEvent[]
  nextScheduledAction: string
  assignedUsers?: string[]
}

export interface IFilterProps<T> {
  onReset?: TFunc
  filters: Partial<T>
  onUpdate: (_filter: Partial<T>) => void
}

export type TOperator =
  | '='
  | '!='
  | '<'
  | '>'
  | '<='
  | '>='
  | 'contains'
  | 'beginsWith'
  | 'endsWith'
  | 'doesNotContain'
  | 'doesNotBeginWith'
  | 'doesNotEndWith'
  | 'null'
  | 'notNull'
  // | 'in'
  // | 'notIn'
  | 'between'
  | 'notBetween'

export interface IWorkflow {
  _id: string
  crm: string
  name: string
  type: string
  active: boolean
  location: string
  message?: string
  organization: string
  workflowTemplate: string
  combinator: RQBJsonLogic
  sendAfter?: {
    unit: string
    value: number
  }
  actions?: {
    assignMessage: string[]
  }
  createdAt: string
}

export interface IWorkflowPartial {
  _id: string
  name: string
  type: string
}

export interface ITemplate {
  id: string
  crm: string
  name: string
  actions: IAction[]
  description: string
  type: 'conditionals' | 'appointmentConfirmation'
  shouldShow?: {
    offsetField: boolean
    messageField: boolean
    combinatorField: boolean
  }
  isRequired?: {
    offsetField: boolean
    messageField: boolean
    combinatorField: boolean
  }
  combinator: RQBJsonLogic
}

interface IAction {
  name: string
  label: string
  operators: {
    label?: string
    name: TOperator
  }[]
  values?: {
    label: string
    name: string | number
  }[]
  valueEditorType?: 'select'
}

export interface IUser {
  _id: string
  lastName: string
  firstName: string
}

interface IOperation {
  opcode: string
  totalPrice: IPrice
  isDeclined: string
  opcodeDescription: string
  technicians: ITechnician[]
}

interface IPrice {
  amount: number
  currency: string
}

interface ITechnician {
  externalId: string
  firstName: string | null
  lastName: string | null
}

export interface ISample {
  price: IPrice
  openedAt: string
  vehicle: IVehicle
  createdAt: string
  isDeclined: string
  statusCode: string
  externalId: string
  customer: ICustomer
  closedAt: string | null
  appointmentDate: string
  operations: IOperation[]
  statusDescription: string
  serviceAdvisor: ITechnician
}

interface IVehicle {
  vin: string
  type: string
  year: number
  make: string
  model: string
  modelYear: string
  dates: {
    delivered: string
    inService: string
  }
  odometer: {
    unit: string
    value: number
  }
}

interface ICustomer {
  address: string
  lastName: string
  firstName: string
  externalId: string
  phoneNumber: string
}

export interface IWorkflowState {
  crm: string
  name: string
  type: string
  message?: string
  workflowTemplate: string
  combinator: RQBJsonLogic
  sendAfter?: {
    unit: string
    value: number
  }
  actions?: {
    assignMessage: string[]
  }
}

export interface IRawPayload {
  headers: object
  body: object
  createdAt: string
}

export interface IRepairOrder {
  _id: string
  crm: string
  location: string
  organization: string
  externalId: string
  rawPayloads: IRawPayload[]
  excludedWorkflows: { _id: string; name: string }[]
  createdAt: Date
}

export interface IServiceAppointment extends IRepairOrder {}

export interface ILockedLocation {
  location: ILocation & { _organization_id: string }
  lockedAt: Date
  lockedBy: IUser
  reason: string
  crm: string
  assignedUsers: IUser[]
}

export enum IInventoryStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
}

export interface IInventoryAdmin {
  id: string
  organization: {
    _id: string
    name: string
  } | null
  status: InventoryStatusTypes
  dealer_id: string
  domain_url: string | null
  vendor: InventoryType
  created_at?: Date
  updated_at?: Date
  synced_at?: Date
  modifiedAt?: Date
}

export type IImportJobStatus = 'pending' | 'success' | 'failed'

export interface IImportJob {
  id: string
  mappingId: string
  status: IImportJobStatus
  message: string
  created_at?: Date
  updated_at?: Date
}

export type InventoryType =
  | 'homenet'
  | 'vauto'
  | 'sm360'
  | 'dealersync'
  | 'carsforsales'
  | 'dealerspike'
  | 'edealer'
  | 'vincue'
  | 'serti'
  | 'autosweet'

export type TAutomationFilters = {
  location: IOption[]
  type: IOption[]
}

export type VendorInventory =
  | { vendor: 'homenet'; item: IHomenetInventory }
  | { vendor: 'vauto'; item: IVAutoInventory }
  | { vendor: 'sm360'; item: ISM360Inventory }
  | { vendor: 'dealersync'; item: IDealersyncInventory }
  | { vendor: 'carsforsales'; item: ICarsForSalesInventory }
  | { vendor: 'serti'; item: ISertiInventory }
  | { vendor: 'dealerspike'; item: IDealerSpikeInventory }
  | { vendor: 'edealer'; item: IEDealerInventory }
  | { vendor: 'vincue'; item: IVincueInventory }
  | { vendor: 'autosweet'; item: IAutoSweetInventory }

export type IInventory =
  | IHomenetInventory
  | IVAutoInventory
  | ISM360Inventory
  | IDealersyncInventory
  | ICarsForSalesInventory
  | IEDealerInventory
  | IVincueInventory
  | IAutoSweetInventory

export interface IUnionInventory {
  // common
  id: string
  vin: string
  organizationid: string
  type: string
  stock: string
  year: string
  make: string
  model: string
  body: string
  trim: string
  doors?: string // missing in carsforsales
  exteriorcolor?: string
  interiorcolor: string
  enginecylinders: string
  enginedisplacement: string
  transmission: string
  miles: string
  sellingprice: string
  msrp?: string
  certified: string
  description: string
  drivetrain?: string // missing in carsforsales
  imagelist: string
  // missing in sm360
  dateinstock?: string // missing in carsforsales
  options?: string
  citympg?: string
  highwaympg?: string

  // differences in keys
  dealername: string // dealer name
  dealeraddress: string // dealer address
  dealercity: string // dealer city
  dealerstate: string // dealer state
  dealerzip: string // dealer zip
  dealeremail?: string // dealer email
  dealerphone: string // dealer phone
  wheelbasecode: string // wheelbase_code
  enginedescription: string // engine_description
  engineaspirationtype: string // engine_aspiration_type
  fueltype: string // fuel_type
  transmissionspeed: string // transmission_speed
  transmissiontype: string // transmission_description
  extcolorgeneric: string // ext_color_generic

  // homenet specific
  modelnumber?: string
  bookvalue?: string // serti specific
  invoice?: string
  'categorized options'?: string
  'dealer fax'?: string
  'comment 1'?: string
  'comment 2'?: string
  'comment 3'?: string
  'comment 4'?: string
  'comment 5'?: string
  style_description?: string
  ext_color_code?: string
  int_color_generic?: string
  int_color_code?: string
  int_upholstery?: string
  engine_block_type?: string
  epaclassification?: string
  internet_price?: string
  misc_price1?: string
  misc_price2?: string
  misc_price3?: string
  factory_codes?: string
  marketclass?: string // dealerspike specific / vincue specific
  passengercapacity?: string
  extcolorhexcode?: string
  intcolorhexcode?: string
  enginedisplacementcubicinches?: string

  // vauto specific
  disp?: string
  bodytype?: string
  drivetraindesc?: string
  drivetrainwheelct?: string
  recommendsimilar?: string
  record_date?: string

  vdp_url?: string
  listing_features?: string
  vehicle_fuel_type?: string
  portal_deal_ratings?: string
  portal_urls?: string
  vehicle_color_exterior?: string

  // sm360 specific
  dealerid?: string
  remotedatemodified?: string
  remotedateentered?: string
  status?: string
  isdemo?: string
  vehicletype?: string // dealerspike specific
  warranty?: string
  passengercount?: string
  specialmentions?: string
  inservicedate?: string // serti specific
  stockurl?: string // dealerspike specific / vincue specific
  mainphoto?: string
  regularprice: string
  videoen?: string
  videofr?: string
  stockstatus?: string
  clean_carfax?: string
  one_owner?: string

  // dealerspike specific
  dealercountry?: string
  dealercontactname?: string
  modelcode?: string // serti specific
  stockgeindays?: string
  intcolorgeneric?: string
  dealercertified?: string
  features?: string
  location?: string
  showroomtitle?: string
  carfaxhighlights?: string
  subtype?: string
  weight?: string // serti specific
  enginetype?: string // serti specific
  horsepower?: string
  height?: string
  length?: string // serti specific
  width?: string
  drive?: string
  starter?: string
  wheelbase?: string
  groundclearance?: string
  seatheight?: string

  // edealer specific
  modeldescription?: string // serti specific
  seats?: string
  intransit?: string
  demo?: string
  videoembedcode?: string
  suggestedprice?: string
  costprice?: string
  imagelastmodified?: string
  exteriorcolorcode?: string
  interiorcolorcode?: string
  optioncodes?: string
  trimoptions?: string

  // vincue specific
  comparetoprice?: string
  phototimestamp?: string
  lotprice?: string
  carfaxoneowner?: string
  cabtype?: string
  mfrmodel?: string
  tag?: string

  // serti specific
  recordid?: string
  extcolorcode?: string
  interiortrim?: string
  interiortrim2?: string
  intcolorcode?: string
  exteriortrim?: string
  enginenumber?: string
  productcode?: string
  reconditioningcost?: string
  dealeraddress2?: string
  availabilityflag?: string
  orderdate?: string
  productiondate?: string
  deliverydate?: string
  verificationdate?: string
  odometerreadingdate?: string
  odometertype?: string
  payloadcapacity?: string
  totallength?: string
  numberofaxles?: string
  category?: string
  traction?: string
  differential?: string
  suspension?: string
  shippingmethod?: string
  colorlong?: string
  colortype?: string
  warranty1months?: string
  warranty1odometer?: string
  warranty1expiration?: string
  warranty2months?: string
  warranty2odometer?: string
  warranty2expiration?: string
  extendedwarrantymonths?: string
  extendedwarrantyodometer?: string
  extendedwarrantyexpiration?: string
  extendedwarrantydeductible?: string
  extendedwarrantycontract?: string
  extendedwarrantycompany?: string
  extendedwarrantyname?: string
  extendedwarrantydescription?: string
  accessorygroup?: string
  accessorycode?: string
  accessorydescription?: string
  accessoryprice?: string
  excisetax?: string
  freightretailamount?: string
  holdbackamount?: string
  basiccost?: string
  extendedwarrantycode?: string
  certifiednumber?: string
  keycode?: string
  trunkkeycode?: string
  radiocode?: string
  alarmcode?: string
  gpscode?: string
  origincode?: string
  origindescription?: string
  reconditioningmaintenancecode?: string
  preparationretail?: string
  administrationfeesretail?: string
  licensefeesretail?: string
  otherfeesretail?: string
  gasfeesretail?: string
  gastaxretail?: string
  recyclingfeesretail?: string
  batterylevy?: string
  glaccount?: string
  imagenote?: string
  sellingprice2?: string
  employeeprice1?: string
  employeeprice2?: string
  purchasedfrom?: string
  accessoriesoptionscost?: string
  vehiclestatus?: string
  ordernumber?: string

  // autosweet specific
  exportdealerid: string
  series: string
  certificationtype: string
  certificationid: string
  listprice: string
  reserved1: string
  daysinstock: string
  manufacturermodelnu: string
  transmissiondescription: string
  solddate: string
  technicalspecification: string
  reserved2: string
  cost: string
}

export interface IUnusedFeed {
  dealer: string
  modifiedAt: Date
}

export interface IInventoryFile {
  name: string
  modifiedAt: Date
}
